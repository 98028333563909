
import logo from '@/assets/images/logo-texte-easy-rachat-cc-orange.svg'
import { routes } from '@/router.js'
export default {
  props: {
    open: {
      type: Boolean
    }
  },
  data: () => ({
    routes,
    logo
  }),
  mounted () {}
}
