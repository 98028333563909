import { render, staticRenderFns } from "./page.vue?vue&type=template&id=4c09678e&scoped=true&lang=html&"
import script from "./page.vue?vue&type=script&lang=js&"
export * from "./page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c09678e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageSkeleton: require('/var/www/app/components/base/skeletons/pageSkeleton.vue').default,PageHeading: require('/var/www/app/components/base/headings/pageHeading.vue').default,KsParticles: require('/var/www/app/components/base/effects/ksParticles.vue').default,SectionWrapper: require('/var/www/app/components/base/wrappers/sectionWrapper.vue').default,SkewedCard: require('/var/www/app/components/base/skewedCard.vue').default,PageWrapper: require('/var/www/app/components/base/wrappers/pageWrapper.vue').default})
