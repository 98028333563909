// Nuxt component vof vue-unicons
// https://iconscout.com/unicons

import Vue from 'vue'
import Unicon from 'vue-unicons/dist/vue-unicons-ssr.common.js'
import 'vue-unicons/dist/vue-unicons-ssr.css'
import {
  uniCommentAltMessageMonochrome,
  uniCommentMessageMonochrome,
  uniClockMonochrome,
  uniStopwatch,
  uniEuro,
  uniUserArrowsMonochrome,
  uniSmileBeam,
  uniCheckCircleMonochrome,
  uniFacebookF,
  uniLinkedinAlt,
  uniInstagram,
  uniDocumentLayoutCenterMonochrome,
  uniHome,
  uniCommentInfo,
  uniTagAlt,
  uniDocumentLayoutCenter,
  uniEuroCircle,
  uniAngleDown,
  uniPhone,
  uniCheckCircle,
  uniNotes,
  uniSubwayAlt,
  uniAngleDoubleRight,
  uniMessage,
  uniBars,
  uniTimes
} from 'vue-unicons/src/icons'

Unicon.add([
  uniCommentMessageMonochrome,
  // uniChatBubbleUserMonochrome,
  uniCommentAltMessageMonochrome,
  uniClockMonochrome,
  uniStopwatch,
  uniSmileBeam,
  uniUserArrowsMonochrome,
  uniEuro,
  uniCheckCircleMonochrome,
  uniFacebookF,
  uniLinkedinAlt,
  uniInstagram,
  uniDocumentLayoutCenterMonochrome,
  uniHome,
  uniCommentInfo,
  uniTagAlt,
  uniDocumentLayoutCenter,
  uniEuroCircle,
  uniAngleDown,
  uniPhone,
  uniCheckCircle,
  uniNotes,
  uniSubwayAlt,
  uniAngleDoubleRight,
  uniMessage,
  uniBars,
  uniTimes
])
Vue.use(Unicon)
