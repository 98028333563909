
export default {
  layout: 'mainForm',
  data: () => ({
    pass: ''
  }),
  methods: {
    async auth () {
      const data = await this.$passwordProtect.authorise(this.pass)
      if (data) {
        this.$router.push('/')
      }
    }
  }
}
