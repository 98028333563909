
export default {
  props: {
    cover: {
      type: Object
    },
    title: {
      type: String
    },
    icon: {
      type: String
    },
    intro: {
      type: String
    },
    noBottom: {
      type: Boolean
    },
    alignIntro: {
      type: String,
      default: 'left'
    },
    noImg: {
      type: Boolean
    }
  }
}
