
export default {
  scrollToTop: true,
  async fetch () {
    try {
      this.page = await this.$strapi.find(this.$route.name)
    } catch (e) {
      console.log(e);
    }
  },
  data: () => ({
    page: {}
  })
}
