import { render, staticRenderFns } from "./MentionsLegales.vue?vue&type=template&id=0dd7ecd6&"
import script from "./MentionsLegales.vue?vue&type=script&lang=js&"
export * from "./MentionsLegales.vue?vue&type=script&lang=js&"
import style0 from "./MentionsLegales.vue?vue&type=style&index=0&id=0dd7ecd6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageSkeleton: require('/var/www/app/components/base/skeletons/pageSkeleton.vue').default,PageWrapper: require('/var/www/app/components/base/wrappers/pageWrapper.vue').default})
