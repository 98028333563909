const checkWebpSupport = async ({dispatch}) => {
  var kTestImages = {
      lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
      lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
      alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
      animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
  };
  var img = new Image();
  img.src = "data:image/webp;base64," + kTestImages['lossy'];
  img.onload = async () => {
      var result = (img.width > 0) && (img.height > 0);
      await dispatch('customModernizr/setSupports', { webp: result })
  };
  img.onerror = async () => {
      await dispatch('customModernizr/setSupports', { webp: false })
  };
}

export default async ({ store }) => {
  return Promise.all([
    checkWebpSupport(store)
  ])
}
