
export default {
  components: {
    tagger: {
      functional: true,
      render (h, ctx) {
        return h(ctx.props.tag ? ctx.props.tag : 'h' + (ctx.props.level || '2'), {class: 'heading-block__content'},ctx.children)
      }
    }
  },
  props: {
    content: {
      type: String
    },
    tag: {
      type: String
    },
    level: {
      type: String | Number
    },
    size: {
      type: String | Number
    },
    align: {
      type: String
    },
    icon: {
      type: String
    },
    noClipping: {
      type: Boolean
    },
    dark: {
      type: Boolean
    }
  },
  computed: {
    texts () {
      const arr = this.content.split('|')
      return {
        main: arr.length > 1 ? arr[1] : this.content,
        second: arr.length > 1 ? arr[0] : ''
      }
    }
  }
}
