
export default {
  props: {
    options: {
      type: Object,
      default: () => ({
        fpsLimit: 50,
        // interactivity: {
        //     detectsOn: 'canvas',
        //     events: {
        //         onClick: {
        //             enable: true,
        //             mode: 'push'
        //         },
        //         onHover: {
        //             enable: true,
        //             mode: 'repulse'
        //         },
        //         resize: true
        //     },
        //     modes: {
        //         bubble: {
        //             distance: 400,
        //             duration: 2,
        //             opacity: 0.1,
        //             size: 40
        //         },
        //         push: {
        //             quantity: 4
        //         },
        //         repulse: {
        //             distance: 200,
        //             duration: 0.4
        //         }
        //     }
        // },
        particles: {
            color: {
                value: '#ffffff'
            },
            links: {
                color: '#ffffff',
                distance: 150,
                enable: true,
                opacity: 0.1,
                width: 3
            },
            collisions: {
                enable: true
            },
            move: {
                direction: 'none',
                enable: true,
                outMode: 'bounce',
                random: false,
                speed: 1,
                straight: false
            },
            number: {
                density: {
                    enable: true,
                    value_area: 1000
                },
                value: 40
            },
            opacity: {
                value: 0.8
            },
            shape: {
                type: 'circle'
            },
            size: {
                random: true,
                value: 80
            }
        },
        detectRetina: true
      })
    },
    id: {
      type: String,
      required: true
    },
    opacity: {
      type: Number | String,
      default: 1
    },
    color: {
      type: String | Array,
      default: 'white'
    }
  },
  data: () => ({
    isIntersecting: false
  }),
  methods: {
    initOptions () {
      const opt = {...this.options}
      opt.particles.color = {
        value: Array.isArray(this.color) ? this.color : [this.color]
      }
      return opt
    },
    onIntersect (entries, observer) {
      this.isIntersecting = entries[0].isIntersecting
    },
  }
}
