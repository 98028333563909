
export default {
  props: {
    large: {
      type: Boolean
    },
    xLarge: {
      type: Boolean
    },
    nuxt: {
      type: Boolean
    },
    outlined: {
      type: Boolean
    },
    to: {
      type: String
    }
  }
}
