
import { metaMixin } from '~/mixins'
export default {
  scrollToTop: true,
  async fetch () {
    try {
      const data = await this.$strapi.find('pages', { slug: this.formatedPath })
      if (data && data.length) {
        this.page = data[0]
      } else {
        this.$nuxt.error({ statusCode: 404, message: 'Cette page n\'existe pas' })
      }
    } catch (e) {
      this.$nuxt.error({ statusCode: 404, message: e.message })
    } finally {
      return ''
    }
  },
  // fetchDelay: 400,
  data: () => ({
    page: {},
    users: []
  }),
  mixins: [metaMixin],
  computed: {
    formatedPath () {
      const s = this.$route.fullPath
      return ((s.length && s[0] === '/') ? s.slice(1) : s)
    },
    avatars () {
      return this.users.map(us => us.picture.large)
    }
  },
  methods: {
    setSkew (section_options = {}) {
      switch (section_options.skew) {
        case 'top':
          return { noBottom: true }
        case 'bottom':
          return { noTop: true }
        case 'both':
          return {}
        case 'none':
          return { noBottom: true, noTop: true }
      }
    },
    backgroundClass (opts = {}) {
      switch (opts.background) {
        case 'orange':
          return 'mha-gradient'
        case 'white':
          return 'white'
        case 'blue':
          return 'secondary darken-3'
        case 'grey':
        default:
          return 'grey lighten-5'
      }
    },
    isDark (section_options = {}) {
      return !section_options.background || (!['white', 'grey'].includes(section_options.background))
    }
  },
  watch: {
    '$route': {
      immediate: true,
      deep: true,
      async handler (val, oldVal) {
        if (process.server || !oldVal) return
        this.$vuetify.goTo(0, { duration: 0 })
        if (oldVal && val.path !== oldVal.path) {
          await this.$fetch()
        }
      }
    }
  }
}
