import Vue from 'vue'
import Router from 'vue-router'

import HomePage from '~/components/core/homePage'
import MentionsLegales from '~/components/core/MentionsLegales'
import Page from '~/components/core/page'
import PassPage from '~/components/core/passPage'

Vue.use(Router)

export const routes = [
  {
    path: '/',
    name: 'index',
    component: HomePage,
    meta: { title: 'Accueil', hideInMenu: true }
  },
  // {
  //   path: '/password',
  //   name: 'password',
  //   component: PassPage,
  //   meta: { title: 'Authentification' }
  // },
  {
    path: '/comment-ca-marche',
    name: 'comment-ca-marche',
    component: Page,
    meta: { title: 'Comment ça marche ?' },
    children: [
      {
        name: 'qui-sommes-nous',
        path: 'qui-sommes-nous',
        component: Page,
        meta: { title: 'Qui sommes-nous ?' }
      }
    ]
  },
  {
    path: '/estimation-camping-car',
    name: 'estimation-camping-car',
    component: Page,
    meta: { title: 'Estimer son camping car' },
    children: [
      {
        path: 'cote-camping-car',
        name: 'cote-camping-car',
        component: Page,
        meta: { title: 'Cote camping car' }
      },
      {
        name: 'calcul-valeur-camping-car',
        path: 'calcul-valeur-camping-car',
        component: Page,
        meta: { title: 'Calcul de valeur' }
      }
    ]
  },
  {
    path: '/vendre-son-fourgon',
    name: 'vendre-son-fourgon',
    component: Page,
    meta: {}
  },
  {
    path: '/vendre-son-camping-car',
    name: 'vendre-son-camping-car',
    component: Page,
    meta: { title: 'Vendre son camping car à un pro' },
    children: [
      {
        path: 'cause-deces-maladie',
        name: 'cause-deces-maladie',
        component: Page,
        meta: { title: 'Cause décés' }
      },
      {
        name: 'cause-divorce',
        path: 'cause-divorce',
        component: Page,
        meta: { title: 'Cause divorce' }
      },
      {
        name: 'mauvaise-experience-concessionnaire',
        path: 'mauvaise-experience-concessionnaire',
        component: Page,
        meta: { title: 'Mauvaise experience' }
      },
      {
        name: 'accidente-infiltre',
        path: 'accidente-infiltre',
        component: Page,
        meta: { title: 'Accidenté ou infiltré' }
      }
    ]
  },
  {
    path: '/mentions-legales',
    name: 'mentions-legales',
    component: MentionsLegales,
    meta: {}
  },
  {
    path: '/cgu-politique-de-confidentialite',
    name: 'cgu',
    component: MentionsLegales,
    meta: {}
  }
]

export function createRouter() {
  return new Router({
    mode: 'history',
    routes
  })
}
