
import { mapState, mapGetters } from 'vuex'
import logo from '@/assets/images/logo-texte-easy-rachat-cc-orange.svg'
export default {
  data: () => ({
    logo,
    current: null
  }),
  computed: {
    ...mapState({
      navs: state => state.mainNav
    }),
    ...mapGetters({
      activeNav: 'activeNav'
    }),
    // topNavHeight () {
    //   return this.$vuetify.breakpoint.xsOnly ? 56 : 64
    // }
  }
}
