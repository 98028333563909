
import { mapState, mapActions } from 'vuex'
import logoText from '@/assets/images/logo-texte-easy-rachat-cc-orange.svg'
import topNav from '@/components/core/navs/topNav'
import secondaryTopNav from '@/components/core/navs/secondaryTopNav'
import mainFooter from '@/components/core/footers/mainFooter'
import mainNav from '@/components/core/navs/mainNav'
export default {
  components: {
    topNav,
    secondaryTopNav,
    mainFooter,
    mainNav,
    mainForm: () => import('@/components/core/forms/mainForm.vue')
  },
  data: () => ({
    logoText,
    mainNavOpened: false
  }),
  mounted () {
    this.$cookies.modal = true
    this.triggerFormModal()
  },
  computed: {
    ...mapState({
      formOpen: state => state.formOpen
    })
  },
  methods: {
    triggerFormModal () {
      setTimeout(() => !this.$store.state.form_dialog && this.$store.dispatch('showFormDialog', true), 5000)
    }
  }
}
