
export default {
  props: {
    size: {
      type: String | Number,
      default: 64
    },
    iconStyle: {
      type: String,
      default: 'line'
    },
    color: {
      type: String,
      default: '#fff'
    },
    icon: {
      type: String,
      required: true
    }
  }
}
