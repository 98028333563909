export const state = () => ({
  webp: true
})

export const actions = {
  setSupports ({commit}, support) {
    commit('SET_SUPPORTS', support)
  }
}

export const mutations = {
  SET_SUPPORTS (state, support) {
    Object.keys(support).forEach((key, i) => {
      console.log(key);
      state[key] = support[key]
    });

  }
}
