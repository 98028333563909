export const metaMixin = {
  head() {
    const metatags = this.page && this.page.metatags
    if ( !metatags ) return {}
    return {
      title: metatags.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: metatags.description
        }
      ],
    }
  }
}
