
import logo from '~/assets/images/logo-easy-rachat-camping-car.svg'
import svgTruck from '~/assets/images/mdi-truck.svg'
import svgPerson from '~/assets/images/mdi-person.svg'
export default {
  components: {
    introSection: () => import('@/components/scoped/pages/homepage/introSection'),
    engagementsSection: () => import('@/components/scoped/pages/homepage/engagementsSection'),
    testimonialsSection: () => import('@/components/scoped/pages/homepage/testimonialsSection'),
    servicesSection: () => import('@/components/scoped/pages/homepage/servicesSection'),
    formSection: () => import('@/components/scoped/pages/homepage/formSection'),
    avantagesSection: () => import('@/components/scoped/pages/homepage/avantagesSection'),
    brandsSection: () => import('@/components/scoped/pages/homepage/brandsSection')
  },
  scrollToTop: true,
  fetch () {
    return Promise.all([
      this.$strapi.find('homepage'),
      this.$strapi.find('avis-clients')
    ]).then(([page, avisClients]) => {
      this.page = page
      this.reviews = avisClients
    }).catch((e) => {
      console.log(e);
    })
  },
  // fetchDelay: 100,
  head () {
    return {
      script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
  },
  data: () => ({
    page: null,
    reviews: null,
    logo,
    svgTruck,
    svgPerson
  }),
  computed: {
    structuredData () {
      const base = this.$store.state.jsonLdBase
      const review = [{
        "@type": "Review",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "4",
          "bestRating": "5"
        },
        "author": {
          "@type": "Person",
          "name": "Lillian Ruiz"
        }
      }]
      return { ...base, review }
    }
  }
}
