
export default {
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    color: {
      type: String,
    },
    colorTop: {
      type: String
    },
    colorBottom: {
      type: String,
    },
    noTop: {
      type: Boolean
    },
    noBottom: {
      type: Boolean
    },
    dark: {
      type: Boolean
    },
    width: {
      type: String | Number
    },
    height: {
      type: String | Number
    },
    skewHeight: {
      type: String,
      default: '80'
    },
    flat: {
      type: Boolean,
      default: true
    },
    padding: {
      type: String | Number,
      default: '80px'
    },
    paddingTop: {
      type: String | Number
    },
    paddingBottom: {
      type: String | Number
    },
    minHeight: {
      type: [String, Number]
    }
  }
}
