import { render, staticRenderFns } from "./pageHeading.vue?vue&type=template&id=169d93bb&scoped=true&lang=html&"
import script from "./pageHeading.vue?vue&type=script&lang=js&"
export * from "./pageHeading.vue?vue&type=script&lang=js&"
import style0 from "./pageHeading.vue?vue&type=style&index=0&id=169d93bb&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "169d93bb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeadingBlock: require('/var/www/app/components/base/headings/headingBlock.vue').default,BaseMdRender: require('/var/www/app/components/base/baseMdRender.vue').default,SkewedCard: require('/var/www/app/components/base/skewedCard.vue').default})
