import { routes } from '@/router'
const mainNav = routes.filter(route => !['password', 'index'].includes(route.name)).map((route) => {
  const children = (route.children || []).map(({ path, name, meta}) => ({ path: `${route.path}/${path}`, name, meta, title: meta.title}))
  return {
    name: route.name,
    path: route.path,
    title: route.meta.title,
    meta: route.meta,
    children
  }
})

export const state = () => ({
  route: null,
  mainNav,
  formOpen: false,
  jsonLdBase: {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Easy Rachat Camping Car",
    "contactPoint": {
      "@type": "ContactPoint",
      "url": "https://easy-rachat-camping-car.fr",
      "email": "contact@easy-rachat-camping-car.fr",
      "contactType": "Service client"
    }
  },
  form_dialog: false
})

export const getters = {
  activeNav (state) {
    const activNav = state.mainNav.find((nav) => {
      return state.route && state.route.name && state.route.path.includes(nav.name)
    } ) || {}
    return activNav
  }
}

export const actions = {
  setRoute ({ state, commit }, route) {
    commit('SET_ROUTE', route)
  },
  showFormDialog ({ state, commit}, bool = false) {
    commit('TOGGLE_FORM_DIALOG', bool)
  }
}

export const mutations = {
  SET_ROUTE (state, route) {
    state.route = route
  },
  TOGGLE_FORM (state, bool) {
    state.formOpen = bool
  },
  TOGGLE_FORM_DIALOG (state, bool) {
    state.form_dialog = bool
  }
}
