
export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    formated: ''
  }),
  watch: {
    content: {
      immediate: true,
      async handler (val) {
        this.formated = await this.$md.render(val)
      }
    }
  }
}
