import { render, staticRenderFns } from "./passPage.vue?vue&type=template&id=c45cba98&scoped=true&"
import script from "./passPage.vue?vue&type=script&lang=js&"
export * from "./passPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c45cba98",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KsBtn: require('/var/www/app/components/base/btns/ksBtn.vue').default})
