
export default {
  name: 'sectionWrapper',
  components: {
    'sections.default': () => import('@/components/scoped/sections/default'),
    'sections.withList': () => import('@/components/scoped/sections/withList'),
    'sections.custom': () => import('@/components/scoped/sections/custom')
  },
  props: {
    section: {
      type: Object
    },
    dark: {
      type: Boolean
    }
  }
}
